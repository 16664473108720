import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CustomCarousel.css';

function CustomCarousel() {
  return (
    <div className="carousel-container">
      <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} showIndicators={false} showStatus={false}>
        <div className="carousel-slide">
          <p>24/7 Support For You.<br />We are always here to help you.</p>
          <h1>IPTV HELP CENTER</h1>
        </div>
        {/* Add more slides if needed */}
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
