import React from 'react';
import './HelpSection.css';

function HelpSection() {
  const openWhatsApp = (message) => {
    const phoneNumber = '+447845450412'; // Replace with your phone number, in international format
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.location.href = url;
  };

  return (
    <div className="help-section">
      <div className="help-item">
        <h3>Existing Account</h3>
        <p>Enter your problem related to your Existing Account, We are always here to help you.</p>
        <button className="help-button" onClick={() => openWhatsApp('Problem Of Existing Subscription Of IPTV')}>
          <i className="fab fa-whatsapp"></i> Problem Of Existing Subscription Of IPTV
        </button>
      </div>
      <div className="help-item">
        <h3>New Account</h3>
        <p>New IPTV service for access to lots of channels and on-demand content.</p>
        <button className="help-button" onClick={() => openWhatsApp('New IPTV Service Subscription')}>
          <i className="fab fa-whatsapp"></i> New IPTV Service Subscription
        </button>
      </div>
      <div className="help-item">
        <h3>More Help</h3>
        <p>For more help, Contact us on Whatsapp, We are always here to help you.</p>
        <button className="help-button" onClick={() => openWhatsApp('More Help Related To IPTV')}>
          <i className="fab fa-whatsapp"></i> More Help Related To IPTV
        </button>
      </div>
    </div>
  );
}

export default HelpSection;
