import React from 'react';
import './WhatsAppChatButton.css';

function WhatsAppChatButton() {
  const phoneNumber = '+447845450412'; // Replace with your phone number, in international format

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp-chat-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
}

export default WhatsAppChatButton;
