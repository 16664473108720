import React from 'react';
import './Header.css';

function Header() {
  return (
    <div className="header-container">
      <div className="header-content">
        <h4>IPTV Help Center</h4>
      </div>
    </div>
  );
}

export default Header;
